/**
 * @IMPORTANT
 * Colors defined here are also defined in an enum inside $qima-color.enum.ts file
 * Each color change has to be applied to both files
 */
@import '@angular/cdk/overlay-prebuilt.css';
.qima-autolink {
  outline: none;
}
.qima-autolink:focus, .qima-autolink:focus-visible {
  outline: none;
}

.qima-h6, .qima-h5, .qima-h4, .qima-h3, .qima-h2, .qima-h1 {
  margin: 0;
  font-weight: normal;
}

.qima-body2, .qima-body1 {
  margin: 0;
}

.qima-shadow-minus {
  box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.qima-shadow-zero {
  box-shadow: none;
}

.qima-shadow-one {
  box-shadow: 0 2px 10px 0 rgba(25, 25, 25, 0.1);
}

.qima-shadow-two {
  box-shadow: 0 4px 20px 0 rgba(25, 25, 25, 0.15);
}

.qima-shadow-two-left {
  box-shadow: -4px 0 20px 0 rgba(25, 25, 25, 0.15);
}

.qima-shadow-two-right {
  box-shadow: 4px 0 20px 0 rgba(25, 25, 25, 0.15);
}

.qima-shadow-two-top {
  box-shadow: 0 -4px 20px 0 rgba(25, 25, 25, 0.15);
}

.qima-overlay-pane cdk-dialog-container, .qima-shadow-three {
  box-shadow: 0 8px 40px 0 rgba(25, 25, 25, 0.2);
}

.qima-body1 {
  font-size: 1em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.25em;
}

.qima-body2 {
  font-size: 0.875em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.4285714286em;
}

.qima-h1 {
  font-size: 2.8125em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.1555555556em;
}

.qima-h2 {
  font-size: 2.3125em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.1891891892em;
}

.qima-h3 {
  font-size: 1.9375em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.1612903226em;
}

.qima-h4 {
  font-size: 1.625em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.2307692308em;
}

.qima-h5 {
  font-size: 1.375em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.2727272727em;
}

.qima-h6 {
  font-size: 1.125em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.3333333333em;
}

@media print, screen and (prefers-reduced-motion: reduce) {
  *:not(.qima-input-textarea__input)::-webkit-scrollbar-thumb, .qima-autolink {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

body {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
body > * {
  -webkit-transform: translateZ(0);
}

@keyframes qimaAnimationFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes qimaAnimationFadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes qimaAnimationFadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes qimaAnimationFadeOutLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes qimaAnimationFadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes qimaAnimationFadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes qimaAnimationFakeLoading {
  100% {
    transform: translateX(100%);
  }
}
@keyframes qimaAnimationRotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes qimaAnimationShrink {
  0% {
    height: 100%;
    width: 100%;
  }
  30% {
    height: 50%;
    width: 50%;
  }
  60%, 100% {
    height: 100%;
    width: 100%;
  }
}
@keyframes qimaAnimationZoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
body {
  color: #191919;
  line-height: 1.5;
}
body.qima-font-family-noto {
  font-family: "Noto Sans SC", sans-serif;
}
body.qima-font-family-noto input, body.qima-font-family-noto button, body.qima-font-family-noto textarea {
  font-family: "Noto Sans SC", sans-serif;
}
body.qima-font-family-inter {
  font-family: "Inter", sans-serif;
}
body.qima-font-family-inter input, body.qima-font-family-inter button, body.qima-font-family-inter textarea {
  font-family: "Inter", sans-serif;
}

.qima-global-scroll-block {
  display: block !important;
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.qima-normal {
  font-weight: normal !important;
}

.qima-semi-bold {
  font-weight: 600 !important;
}

.qima-bold {
  font-weight: bold !important;
}

.qima-autolink {
  transition-property: color;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #4169E1;
}
.qima-autolink:focus, .qima-autolink:hover {
  text-decoration: underline;
  color: #1E46BD;
}

/* Below are two of forty example classes after build
```
  .qima-mt-min {
    margin-top: $qima-spacing-min;
  }
  .qima-m-min {
    margin: $qima-spacing-min;
  }
```
 */
.qima-mt-min {
  margin-top: 0.125em !important;
}

.qima-mr-min {
  margin-right: 0.125em !important;
}

.qima-mb-min {
  margin-bottom: 0.125em !important;
}

.qima-ml-min {
  margin-left: 0.125em !important;
}

.qima-m-min {
  margin: 0.125em !important;
}

.qima-mt-base {
  margin-top: 0.25em !important;
}

.qima-mr-base {
  margin-right: 0.25em !important;
}

.qima-mb-base {
  margin-bottom: 0.25em !important;
}

.qima-ml-base {
  margin-left: 0.25em !important;
}

.qima-m-base {
  margin: 0.25em !important;
}

.qima-mt-xs {
  margin-top: 0.5em !important;
}

.qima-mr-xs {
  margin-right: 0.5em !important;
}

.qima-mb-xs {
  margin-bottom: 0.5em !important;
}

.qima-ml-xs {
  margin-left: 0.5em !important;
}

.qima-m-xs {
  margin: 0.5em !important;
}

.qima-mt-s {
  margin-top: 0.75em !important;
}

.qima-mr-s {
  margin-right: 0.75em !important;
}

.qima-mb-s {
  margin-bottom: 0.75em !important;
}

.qima-ml-s {
  margin-left: 0.75em !important;
}

.qima-m-s {
  margin: 0.75em !important;
}

.qima-mt-m {
  margin-top: 1em !important;
}

.qima-mr-m {
  margin-right: 1em !important;
}

.qima-mb-m {
  margin-bottom: 1em !important;
}

.qima-ml-m {
  margin-left: 1em !important;
}

.qima-m-m {
  margin: 1em !important;
}

.qima-mt-l {
  margin-top: 1.5em !important;
}

.qima-mr-l {
  margin-right: 1.5em !important;
}

.qima-mb-l {
  margin-bottom: 1.5em !important;
}

.qima-ml-l {
  margin-left: 1.5em !important;
}

.qima-m-l {
  margin: 1.5em !important;
}

.qima-mt-xl {
  margin-top: 2.25em !important;
}

.qima-mr-xl {
  margin-right: 2.25em !important;
}

.qima-mb-xl {
  margin-bottom: 2.25em !important;
}

.qima-ml-xl {
  margin-left: 2.25em !important;
}

.qima-m-xl {
  margin: 2.25em !important;
}

.qima-mt-xxl {
  margin-top: 3em !important;
}

.qima-mr-xxl {
  margin-right: 3em !important;
}

.qima-mb-xxl {
  margin-bottom: 3em !important;
}

.qima-ml-xxl {
  margin-left: 3em !important;
}

.qima-m-xxl {
  margin: 3em !important;
}

/* Below are two of forty example classes after build
```
  .qima-pt-min {
    padding-top: $qima-spacing-min;
  }
  .qima-p-min {
    padding: $qima-spacing-min;
  }
```
 */
.qima-pt-min {
  padding-top: 0.125em !important;
}

.qima-pr-min {
  padding-right: 0.125em !important;
}

.qima-pb-min {
  padding-bottom: 0.125em !important;
}

.qima-pl-min {
  padding-left: 0.125em !important;
}

.qima-p-min {
  padding: 0.125em !important;
}

.qima-pt-base {
  padding-top: 0.25em !important;
}

.qima-pr-base {
  padding-right: 0.25em !important;
}

.qima-pb-base {
  padding-bottom: 0.25em !important;
}

.qima-pl-base {
  padding-left: 0.25em !important;
}

.qima-p-base {
  padding: 0.25em !important;
}

.qima-pt-xs {
  padding-top: 0.5em !important;
}

.qima-pr-xs {
  padding-right: 0.5em !important;
}

.qima-pb-xs {
  padding-bottom: 0.5em !important;
}

.qima-pl-xs {
  padding-left: 0.5em !important;
}

.qima-p-xs {
  padding: 0.5em !important;
}

.qima-pt-s {
  padding-top: 0.75em !important;
}

.qima-pr-s {
  padding-right: 0.75em !important;
}

.qima-pb-s {
  padding-bottom: 0.75em !important;
}

.qima-pl-s {
  padding-left: 0.75em !important;
}

.qima-p-s {
  padding: 0.75em !important;
}

.qima-pt-m {
  padding-top: 1em !important;
}

.qima-pr-m {
  padding-right: 1em !important;
}

.qima-pb-m {
  padding-bottom: 1em !important;
}

.qima-pl-m {
  padding-left: 1em !important;
}

.qima-p-m {
  padding: 1em !important;
}

.qima-pt-l {
  padding-top: 1.5em !important;
}

.qima-pr-l {
  padding-right: 1.5em !important;
}

.qima-pb-l {
  padding-bottom: 1.5em !important;
}

.qima-pl-l {
  padding-left: 1.5em !important;
}

.qima-p-l {
  padding: 1.5em !important;
}

.qima-pt-xl {
  padding-top: 2.25em !important;
}

.qima-pr-xl {
  padding-right: 2.25em !important;
}

.qima-pb-xl {
  padding-bottom: 2.25em !important;
}

.qima-pl-xl {
  padding-left: 2.25em !important;
}

.qima-p-xl {
  padding: 2.25em !important;
}

.qima-pt-xxl {
  padding-top: 3em !important;
}

.qima-pr-xxl {
  padding-right: 3em !important;
}

.qima-pb-xxl {
  padding-bottom: 3em !important;
}

.qima-pl-xxl {
  padding-left: 3em !important;
}

.qima-p-xxl {
  padding: 3em !important;
}

*:not(.qima-input-textarea__input)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #F7F7F7;
  border-radius: 16px;
}
*:not(.qima-input-textarea__input)::-webkit-scrollbar {
  width: 4px;
  height: 16px;
}
*:not(.qima-input-textarea__input)::-webkit-scrollbar-thumb {
  transition-property: background-color;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  border-radius: 16px;
  background-color: #DEDEDE;
}
*:not(.qima-input-textarea__input)::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: #B3B3B3;
}
*:not(.qima-input-textarea__input)::-webkit-scrollbar-thumb:focus {
  background-color: #B3B3B3;
}
*:not(.qima-input-textarea__input)::-webkit-scrollbar:horizontal {
  height: 4px;
}

/**
 * @IMPORTANT
 * Colors defined here are also defined in an enum inside $qima-color.enum.ts file
 * Each color change has to be applied to both files
 */
.qima-autolink {
  outline: none;
}
.qima-autolink:focus, .qima-autolink:focus-visible {
  outline: none;
}

.qima-h1, .qima-h2, .qima-h3, .qima-h4, .qima-h5, .qima-h6 {
  margin: 0;
  font-weight: normal;
}

.qima-body1, .qima-body2 {
  margin: 0;
}

.qima-shadow-minus {
  box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.qima-shadow-zero {
  box-shadow: none;
}

.qima-shadow-one {
  box-shadow: 0 2px 10px 0 rgba(25, 25, 25, 0.1);
}

.qima-shadow-two {
  box-shadow: 0 4px 20px 0 rgba(25, 25, 25, 0.15);
}

.qima-shadow-two-left {
  box-shadow: -4px 0 20px 0 rgba(25, 25, 25, 0.15);
}

.qima-shadow-two-right {
  box-shadow: 4px 0 20px 0 rgba(25, 25, 25, 0.15);
}

.qima-shadow-two-top {
  box-shadow: 0 -4px 20px 0 rgba(25, 25, 25, 0.15);
}

.qima-overlay-pane cdk-dialog-container, .qima-shadow-three {
  box-shadow: 0 8px 40px 0 rgba(25, 25, 25, 0.2);
}

.qima-body1 {
  font-size: 1em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.25em;
}

.qima-body2 {
  font-size: 0.875em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.4285714286em;
}

.qima-h1 {
  font-size: 2.8125em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.1555555556em;
}

.qima-h2 {
  font-size: 2.3125em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.1891891892em;
}

.qima-h3 {
  font-size: 1.9375em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.1612903226em;
}

.qima-h4 {
  font-size: 1.625em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.2307692308em;
}

.qima-h5 {
  font-size: 1.375em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.2727272727em;
}

.qima-h6 {
  font-size: 1.125em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.3333333333em;
}

@media print, screen and (prefers-reduced-motion: reduce) {
  .qima-autolink, *:not(.qima-input-textarea__input)::-webkit-scrollbar-thumb {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

body {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
body > * {
  -webkit-transform: translateZ(0);
}

/* stylelint-disable-next-line selector-type-no-unknown */
.qima-overlay-pane cdk-dialog-container {
  position: relative;
  padding: 48px;
  width: 720px;
  border-radius: 16px;
  background: #FFFFFF;
}

@font-face {
  font-family: "Inter";
  src: url("/ngx-qima/assets/fonts/inter/regular/Inter-Regular.ttf") format("truetype"), url("/ngx-qima/assets/fonts/inter/regular/Inter-Regular.eot"), url("/ngx-qima/assets/fonts/inter/regular/Inter-Regular.woff2") format("truetype"), url("/ngx-qima/assets/fonts/inter/regular/Inter-Regular.woff") format("truetype"); /* Modern Browsers */
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/ngx-qima/assets/fonts/inter/semi-bold/Inter-SemiBold.eot"), url("/ngx-qima/assets/fonts/inter/semi-bold/Inter-SemiBold.ttf") format("truetype"), url("/ngx-qima/assets/fonts/inter/semi-bold/Inter-SemiBold.woff2") format("truetype"), url("/ngx-qima/assets/fonts/inter/semi-bold/Inter-SemiBold.woff") format("truetype");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/ngx-qima/assets/fonts/inter/bold/Inter-Bold.eot"), url("/ngx-qima/assets/fonts/inter/bold/Inter-Bold.ttf") format("truetype"), url("/ngx-qima/assets/fonts/inter/bold/Inter-Bold.woff2") format("truetype"), url("/ngx-qima/assets/fonts/inter/bold/Inter-Bold.woff") format("truetype");
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SC";
  src: url("/ngx-qima/assets/fonts/noto-sans-sc/regular/NotoSansSC-Regular.eot"), url("/ngx-qima/assets/fonts/noto-sans-sc/regular/NotoSansSC-Regular.otf") format("truetype"), url("/ngx-qima/assets/fonts/noto-sans-sc/regular/NotoSansSC-Regular.woff2") format("truetype"), url("/ngx-qima/assets/fonts/noto-sans-sc/regular/NotoSansSC-Regular.woff") format("truetype");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SC";
  src: url("/ngx-qima/assets/fonts/noto-sans-sc/medium/NotoSansSC-Medium.otf") format("truetype"), url("/ngx-qima/assets/fonts/noto-sans-sc/medium/NotoSansSC-Medium.woff2") format("truetype"), url("/ngx-qima/assets/fonts/noto-sans-sc/medium/NotoSansSC-Medium.woff") format("truetype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SC";
  src: url("/ngx-qima/assets/fonts/noto-sans-sc/bold/NotoSansSC-Bold.otf") format("truetype"), url("/ngx-qima/assets/fonts/noto-sans-sc/bold/NotoSansSC-Bold.woff2") format("truetype"), url("/ngx-qima/assets/fonts/noto-sans-sc/bold/NotoSansSC-Bold.woff") format("truetype");
  font-weight: bold;
  font-display: swap;
}